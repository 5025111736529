<template>
  <v-col class="px-0 py-0">
    <success-snackbar
      v-if="showSuccessSnackbar"
      snackbarText="Зміни успішно збережено"
    />
    <loader v-if="showLoader" />
    <v-col v-else class="px-0 py-0">
      <div class="sectionBackground">
        <v-row no-gutters align="center">
          <p class="sectionTitle">Телефони диспетчерів</p>
          <v-row no-gutters justify="end"
            ><span class="textCalculation"
              >{{ dispatchersPhoneNumbers.length }}/4</span
            >
          </v-row>
        </v-row>

        <div class="sectionLine" />
        <v-row
          no-gutters
          align="center"
          v-for="(phone, index) in dispatchersPhoneNumbers"
          :key="phone.id"
        >
          <v-col cols="4" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              placeholder="Введіть телефон"
              label="Телефон"
              style="border-radius: 10px"
              height="48px"
              v-model="phone.value"
              v-mask="'+###############'"
              @input="
                !isEditDispatchersPhoneNumbersIndex.includes(index)
                  ? isEditDispatchersPhoneNumbersIndex.push(index)
                  : ''
              "
            />
          </v-col>
          <div
            class="deleteIcon"
            style="margin-left: 12px; cursor: pointer; margin-bottom: 26px;"
            @click="deleteSetting(phone.id)"
          />
        </v-row>

        <add-new-item-btn
          style="margin-top: 20px"
          text="Додати номер"
          :disabled="
            dispatchersPhoneNumbers.length >= 4 ||
            $v.dispatchersPhoneNumbers.$invalid
          "
          @click="
            dispatchersPhoneNumbers.length < 4
              ? addNewDispatchersPhoneNumber()
              : ''
          "
        ></add-new-item-btn>
      </div>
      <div class="sectionBackground">
        <v-row no-gutters align="center">
          <p class="sectionTitle">Служба підтримки</p>
          <v-row no-gutters justify="end"
            ><span class="textCalculation">{{ supportNumbers.length }}/4</span>
          </v-row>
        </v-row>
        <div class="sectionLine" />
        <v-row
          no-gutters
          align="center"
          v-for="phone in supportNumbers"
          :key="phone.id"
        >
          <v-col cols="4" class="px-0 py-0">
            <v-text-field
              outlined
              dense
              placeholder="Введіть телефон"
              label="Телефон"
              style="border-radius: 10px"
              height="48px"
              v-model="phone.value"
              v-mask="'+###############'"
              @input="
                !isEditSupportNumberIndex.includes(index)
                  ? isEditSupportNumberIndex.push(index)
                  : ''
              "
            />
          </v-col>
          <div
            class="deleteIcon"
            style="margin-left: 12px; cursor: pointer; margin-bottom: 26px;"
            @click="deleteSetting(phone.id)"
          />
        </v-row>

        <add-new-item-btn
          style="margin-top: 20px"
          text="Додати телефон"
          :disabled="supportNumbers.length >= 4 || $v.supportNumbers.$invalid"
          @click="supportNumbers.length < 4 ? addNewSupportNumber() : ''"
        ></add-new-item-btn>
      </div>
      <div class="sectionBackground">
        <p class="sectionTitle">Адреса та Email</p>
        <div class="sectionLine" />
        <v-row no-gutters align="center">
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              placeholder="Введіть адресу"
              label="Адреса"
              style="border-radius: 10px"
              height="48px"
              hide-details
              v-model="address.value"
            />
          </v-col>
          <v-col cols="3" style="margin-left: 20px">
            <v-text-field
              outlined
              dense
              placeholder="Введіть email"
              label="Email"
              style="border-radius: 10px"
              height="48px"
              hide-details
              v-model="email.value"
            />
          </v-col>
        </v-row>
      </div>
      <div class="sectionBackground" style="margin-bottom: 40px">
        <p class="sectionTitle">Соціальні мережі</p>
        <p class="sectionText">
          Додайте соціальні мережі, щоб ваші клієнти отримували актуальну
          інформацію про вас
        </p>
        <div class="sectionLine" />
        <v-row
          no-gutters
          align="center"
          style="margin-bottom: 20px"
          v-for="(link, index) in socialLinks"
          :key="link.id"
        >
          <div
            v-if="!link.image.path"
            style="
              background: #e7edf6;
              padding: 12px 16px;
              width: max-content;
              border-radius: 10px;
              margin-right: 20px;
              user-select: none;
              cursor: pointer;
            "
            @click="showImageGallery(index)"
          >
            <v-row no-gutters align="center">
              <img
                src="@/assets/img/iconsSvg/imageIcon.svg"
                alt="image"
                style="margin-right: 10px"
              />
              <span>Завантажити фото</span>
            </v-row>
          </div>
          <div
            v-else
            style="
              border-radius: 10px;
              background: #4b5262;
              padding: 4px;
              width: 48px;
              height: 48px;
              margin-right: 20px;
              cursor: pointer;
            "
            @click="showImageGallery(index)"
          >
            <img
              :src="link.image.path"
              alt="social img"
              width="100%"
              height="100%"
            />
          </div>
          <v-col cols="3">
            <v-text-field
              outlined
              dense
              placeholder="Введіть тип"
              label="Тип"
              style="border-radius: 10px"
              height="48px"
              hide-details
              v-model="link.key"
              @input="
                !isEditSocialLinksIndex.includes(index)
                  ? isEditSocialLinksIndex.push(index)
                  : ''
              "
            />
          </v-col>
          <v-col cols="3" style="margin-left: 20px">
            <v-text-field
              outlined
              dense
              placeholder="Введіть посилання"
              label="Посилання"
              style="border-radius: 10px"
              height="48px"
              hide-details
              v-model="link.value"
              @input="
                !isEditSocialLinksIndex.includes(index)
                  ? isEditSocialLinksIndex.push(index)
                  : ''
              "
            />
          </v-col>
          <div
            class="deleteIcon"
            style="margin-left: 12px; cursor: pointer"
            @click="deleteSetting(link.id)"
          />
        </v-row>
        <add-new-item-btn
          text="Додати соціальну мережу"
          :disabled="socialLinks.length >= 4 || $v.socialLinks.$invalid"
          @click="socialLinks.length < 4 ? addNewSocialLink() : ''"
        ></add-new-item-btn>
      </div>
      <v-btn
        width="100%"
        class="saveBtn"
        height="48px"
        color="#144FA9"
        style="margin-bottom: 40px"
        :disabled="$v.$invalid"
        @click="saveChanges"
        >Зберегти зміни</v-btn
      >
    </v-col>
    <image-gallery-modal
      v-if="isShowImageGallery"
      :visible="isShowImageGallery"
      type="Social"
      @chooseImage="setSocialImage"
      @close="isShowImageGallery = false"
    />
  </v-col>
</template> 

<script>
import addNewItemBtn from "../../UI/Buttons/addNewItemBtn.vue";
import { validationMixin } from "vuelidate";
import { required, email, minLength } from "vuelidate/lib/validators";
import settingsService from "./../../../requests/Admin/settingsService.js";
import SuccessSnackbar from "../../UI/Snackbars/successSnackbar.vue";
import Loader from "../../UI/Loader.vue";
import ImageGalleryModal from "../../UI/imageGalleryModal.vue";
export default {
  mixins: [validationMixin],
  components: { addNewItemBtn, SuccessSnackbar, Loader, ImageGalleryModal },
  data: () => ({
    dispatchersPhoneNumbers: [],
    supportNumbers: [],
    address: {
      value: "",
    },
    email: {
      value: "",
    },
    socialLinks: [],
    isEditDispatchersPhoneNumbersIndex: [],
    isEditSupportNumberIndex: [],
    isEditSocialLinksIndex: [],
    isEditDispatchersPhoneNumber: false,
    isEditSocialLinks: false,
    isEditEmail: false,
    isEditAddress: false,
    isEditSupportNumber: false,
    showLoader: true,
    isShowImageGallery: false,
    showSuccessSnackbar: false,
    showSuccessModal: false,
  }),
  validations: {
    dispatchersPhoneNumbers: {
      $each: {
        value: {
          required,
          minLength: minLength(10),
        },
      },
    },
    supportNumbers: {
      $each: {
        value: {
          required,
          minLength: minLength(10),
        },
      },
    },
    email: {
      value: {
        email,
      },
    },
    address: {
      required,
    },
    socialLinks: {
      $each: {
        image: {
          required,
        },
        key: {
          required,
        },
        value: {
          required,
        },
      },
    },
  },
  mounted() {
    this.getSettingList();
  },
  methods: {
    addNewDispatchersPhoneNumber() {
      this.dispatchersPhoneNumbers.push({
        id: new Date().getTime(),
        value: "",
        is_new: true,
      });
    },
    addNewSupportNumber() {
      this.supportNumbers.push({
        id: new Date().getTime(),
        value: "",
        is_new: true,
      });
    },
    addNewSocialLink() {
      this.socialLinks.push({
        id: this.socialLinks.length,
        key: "",
        value: "",
        image: {
          path: "",
        },
        is_new: true,
      });
    },
    async getSettingList() {
      await settingsService.getSettingListForAdmin().then((res) => {
        if (res.status == "Success") {
          this.dispatchersPhoneNumbers = res.data.filter(
            (data) => data.type === "Dispatchers"
          );
          this.email = res.data.filter(
            (data) => data.key == "contact_email"
          )?.[0];
          this.address = res.data.filter((data) => data.key == "address")?.[0];
          this.supportNumbers = res.data.filter(
            (data) => data.type == "Contact"
          );
          this.socialLinks = res.data.filter((data) => data.type == "Socials");
        }
        this.showLoader = false;
        setTimeout(() => {
          this.isEditDispatchersPhoneNumber = false;
          this.isEditSupportNumber = false;
          this.isEditEmail = false;
          this.isEditAddress = false;
          this.isEditSocialLinks = false;
        }, 200);
      });
    },
    async saveChanges() {
      if (this.isEditDispatchersPhoneNumber) {
        this.createDispatchersPhoneNumber();
      }
      if (this.isEditSocialLinks) {
        this.createSocialLink();
      }
      if (this.isEditAddress) {
        this.updateAddress();
      }
      if (this.isEditEmail) {
        this.updateEmail();
      }
      if (this.isEditSupportNumber) {
        this.createSupportNumber();
      }
      this.showSuccessSnackbar = true;
      setTimeout(() => {
        this.showSuccessSnackbar = false;
      }, 3000);
    },
    async createDispatchersPhoneNumber() {
      this.$v.dispatchersPhoneNumbers.$touch();
      if (!this.$v.dispatchersPhoneNumbers.$invalid) {
        let form = new FormData();
        this.dispatchersPhoneNumbers.forEach(async (number, index) => {
          if (number.is_new) {
            form.append(
              "key",
              `dispatcher_phone_number ${new Date().getTime()}`
            );
            form.append("value", number.value);
            form.append("can_delete", "Yes");
            form.append("is_hidden", "No");
            form.append("type", "Dispatchers");
            await settingsService.createSetting(form).then((res) => {
              if (res.status == "Success") {
                this.showSuccessModal = true;
                number.is_new = false;
              }
            });
          } else {
            if (this.isEditDispatchersPhoneNumbersIndex.includes(index)) {
              form.append("key", number.key);
              form.append("value", number.value);
              form.append("type", "Dispatchers");
              form.append("can_delete", number.can_delete);
              form.append("is_hidden", number.is_hidden);
              await settingsService
                .updateSetting(number.id, form)
                .then((res) => {
                  if (res.status == "Success") {
                    number.is_new = false;
                  }
                });
            }
          }
        });
      }
    },
    async createSupportNumber() {
      this.$v.supportNumbers.$touch();
      if (!this.$v.supportNumbers.$invalid) {
        let form = new FormData();
        this.supportNumbers.forEach(async (number, index) => {
          if (number.is_new) {
            form.append("key", `support_phone_number ${new Date().getTime()}`);
            form.append("value", number.value);
            form.append("can_delete", "Yes");
            form.append("is_hidden", "No");
            form.append("type", "Contact");
            await settingsService.createSetting(form).then((res) => {
              if (res.status == "Success") {
                this.showSuccessModal = true;
                number.is_new = false;
              }
            });
          } else {
            if (this.isEditSupportNumberIndex.includes(index)) {
              form.append("key", number.key);
              form.append("value", number.value);
              form.append("can_delete", number.can_delete);
              form.append("is_hidden", number.is_hidden);
              form.append("type", "Contact");
              await settingsService
                .updateSetting(number.id, form)
                .then((res) => {
                  if (res.status == "Success") {
                    this.showSuccessModal = true;
                    number.is_new = false;
                  }
                });
            }
          }
        });
      }
    },
    async createSocialLink() {
      let form = new FormData();
      this.socialLinks.forEach(async (link, index) => {
        if (link.is_new) {
          form.append("key", link.key);
          form.append("value", link.value);
          form.append("image_id", link.image.id);
          form.append("can_delete", "Yes");
          form.append("is_hidden", "No");
          form.append("type", "Socials");
          await settingsService.createSetting(form).then((res) => {
            if (res.status == "Success") {
              this.showSuccessModal = true;
              link.is_new = false;
            }
          });
        } else {
          if (this.isEditSocialLinksIndex.includes(index)) {
            form.append("key", link.key);
            form.append("value", link.value);
            form.append("image_id", link.image.id);
            form.append("can_delete", link.can_delete);
            form.append("is_hidden", link.is_hidden);
            form.append("type", "Socials");
            await settingsService.updateSetting(link.id, form).then((res) => {
              if (res.status == "Success") {
                this.showSuccessModal = true;
              }
            });
          }
        }
      });
    },
    async updateAddress() {
      let form = new FormData();
      form.append("key", `address`);
      form.append("value", this.address.value);
      form.append("can_delete", "Yes");
      form.append("is_hidden", "No");
      form.append("type", "common");
      await settingsService.updateSetting(this.address.id, form);
    },
    async updateEmail() {
      this.$v.email.$touch();
      if (!this.$v.email.$invalid) {
        let form = new FormData();
        form.append("key", `contact_email`);
        form.append("value", this.email.value);
        form.append("can_delete", "Yes");
        form.append("is_hidden", "No");
        form.append("type", "common");
        await settingsService.updateSetting(this.email.id, form).then((res) => {
          if (res.status == "Success") {
            setTimeout(() => {
              this.$v.email.value.$reset();
            }, 500);
          }
        });
      }
    },
    async deleteSetting(id) {
      await settingsService.deleteSetting(id).then(() => {
        this.showLoader = true;
        this.getSettingList();
      });
    },
    setSocialImage(img) {
      this.socialLinks[this.socialLinkIndex].image = img;
      !this.isEditSocialLinksIndex.includes(this.socialLinkIndex)
        ? this.isEditSocialLinksIndex.push(this.socialLinkIndex)
        : "";

      this.isShowImageGallery = false;
    },
    showImageGallery(index) {
      this.socialLinkIndex = index;
      this.isShowImageGallery = true;
    },
  },
  watch: {
    dispatchersPhoneNumbers: {
      deep: true,
      handler() {
        this.isEditDispatchersPhoneNumber = true;
      },
    },
    socialLinks: {
      deep: true,
      handler() {
        this.isEditSocialLinks = true;
      },
    },
    supportNumbers: {
      deep: true,
      handler() {
        this.isEditSupportNumber = true;
      },
    },
    address: {
      deep: true,
      handler() {
        this.isEditAddress = true;
      },
    },
    email: {
      deep: true,
      handler() {
        this.isEditEmail = true;
      },
    },
  },
  computed: {
    emailError() {
      const errors = [];
      if (!this.$v.email.value.$dirty) {
        return errors;
      }
      !this.$v.email.value.email && errors.push("");
      return errors;
    },
  },
};
</script>

<style scoped>
.textCalculation {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.saveBtn {
  margin-top: 0px;
  border-radius: 10px;
  color: #fafafa;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  text-transform: none;
}
.saveBtn:hover {
  background-color: #00318b !important;
}
.deleteIcon:hover {
  background-color: #df3a3a;
}
</style>
<style>
.v-text-field--outlined.v-input--dense .v-label {
  top: 18px;
}
.v-text-field--outlined.v-input--dense .v-label--active {
  top: 10px !important;
}
</style>